(function(gallery) {

  // init

  gallery.init = function() {
    $('.carousel').flickity({
  // options
      cellAlign: 'left',
      // contain: true,
      fullscreen: true,
      pageDots: false
    });
  };
  app.addInitMethod(gallery.init);

})(window.app.page = {});

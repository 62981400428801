(function(nav) {

  // init

  nav.init = function() {
    $('[data-behavior="toggle-mobile-nav"]').on('click', nav.toggleMobileNav);
    $('[data-behavior="toggle-category-nav"]').on('click', nav.toggleCategoryNav);
  };
  app.addInitMethod(nav.init);

  nav.toggleMobileNav = function() {
    $('.mobile-nav').toggleClass('open');
    $('.header').toggleClass('fixed');
    $('body').toggleClass('no-scroll');
  };

  nav.toggleCategoryNav = function() {
    $('.category-nav').toggleClass('open');
  };

})(window.app.page = {});

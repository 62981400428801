(function(util) {

  // vars

  util.features = {};
  util.animationEnd = '';
  util.transitionEnd = '';

  // init

  util.init = function() {

    // vars
    util.animationEnd = util.getAnimationEnd();
    util.transitionEnd = util.getTransitionEnd();

  };
  app.addInitMethod(util.init);

  // methods

  util.getAnimationEnd = function() {
    var a
      ,el = document.createElement('fake')
      ,animations = {
        'animation': 'animationend'
        ,'OTransition': 'oAnimationEnd'
        ,'MozTransition': 'animationend'
        ,'WebkitTransition': 'webkitAnimationEnd'
      };
    for(a in animations) {
      if (el.style[a] !== undefined) {
        return animations[a];
      }
    }
  };

  util.getTransitionEnd = function() {
    var t
      ,el = document.createElement('fake')
      ,transitions = {
        'transition': 'transitionend'
        ,'OTransition': 'oTransitionEnd'
        ,'MozTransition': 'transitionend'
        ,'WebkitTransition': 'webkitTransitionEnd'
      };
    for(t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  };

})(window.app.util = {});

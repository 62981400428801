// custom jquery plugins
(function($) {
  $.fn.resizeEnd = function(onResizeEnd, delay) {
    var resizeTimeout;
    $(this).bind('resize.resizeEnd', function() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(function() {
        onResizeEnd();
      }, delay);
    });
    return this;
  };
  $.fn.resizeEndDestroy = function() {
    $(this).unbind('resize.resizeEnd');
    return this;
  };
})(window.jQuery);

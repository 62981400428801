(function(layout) {

  // vars

  layout.resizeMethods = {};
  layout.resizeEndMethods = {};
  layout.scrollMethods = {};
  layout.scrollPosition = 0;

  // init

  layout.init = function() {
    layout.applyBindings();
  };
  app.addPostInitMethod(layout.init);

  // methods

  layout.addResizeMethod = function(method, id) {
    layout.resizeMethods[id] = method;
  };

  layout.addResizeEndMethod = function(method, id) {
    layout.resizeEndMethods[id] = method;
  };

  layout.addScrollMethod = function(method, id) {
    layout.scrollMethods[id] = method;
  };

  layout.removeResizeMethod = function(id) {
    delete layout.resizeMethods[id];
  };

  layout.removeResizeEndMethod = function(id) {
    delete layout.resizeEndMethods[id];
  };

  layout.removeScrollMethod = function(id) {
    delete layout.scrollMethods[id];
  };

  layout.applyBindings = function(debug) {
    if (debug) layout.debugInit();
    layout.render();
    layout.resizeEnd();
    $(window).on('resize.layout', layout.render);
    $(window).on('scroll.layout', layout.scroll);
    $(window).resizeEnd(layout.resizeEnd, 250);
  };

  layout.debugInit = function() {
    var $debug = $('#debug');
    if ($debug.length) return;
    $debug = $('<div id="debug">');
    $debug.css({
      'background': 'rgba(0, 0, 0, .65)'
      ,'bottom': 0
      ,'color': 'white'
      ,'font-size': '11px'
      ,'padding': '1em'
      ,'position': 'fixed'
      ,'right': 0
      ,'z-index': 1000
    });
    $('body').append($debug);
    layout.addResizeMethod(layout.debugSize);
  };

  layout.debugSize = function(windowWidth, windowHeight) {
    $('#debug').text(windowWidth + 'x' + windowHeight + 'px');
  };

  layout.render = function() {
    var windowWidth = $(window).width()
      ,windowHeight = $(window).height();
    for (var i = 0; i < layout.resizeMethods.length; i++) {
      layout.resizeMethods[i](windowWidth, windowHeight);
    }
  };

  layout.resizeEnd = function() {
    for (var i = 0; i < layout.resizeEndMethods.length; i++) {
      layout.resizeEndMethods[i]();
    }
  };

  layout.scroll = function() {
    var scrollPosition = $(window).scrollTop();
    for (var key in layout.scrollMethods) {
      if (layout.scrollMethods.hasOwnProperty(key)) {
        layout.scrollMethods[key](scrollPosition);
      }
    }
    layout.scrollPosition = scrollPosition;
  };

})(window.app.layout = {});

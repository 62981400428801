(function(app) {

  // vars

  app.debug = true;
  app.environment = '';
  app.initMethods = [];
  app.preInitMethods = [];
  app.postInitMethods = [];

  // on ready

  $(function() {
    app.init();
  });

  // methods

  app.addPreInitMethod = function(method) {
    app.preInitMethods.push(method);
  };

  app.addPostInitMethod = function(method) {
    app.postInitMethods.push(method);
  };

  app.addInitMethod = function(method) {
    app.initMethods.push(method);
  };

  app.callback = function(callback) {
    if (typeof callback === 'function') callback();
  };

  app.error = function(message) {
    console.log(message);
  };

  app.init = function() {
    // get environment
    app.environment = $('body').data('environment');
    // don't debug on production
    if (app.environment === 'production') app.debug = false;
    // init methods
    $(function() {
      // pre
      for (i = 0; i < app.addPreInitMethod.length; i++) {
        app.callback(app.addPreInitMethod[i]);
      }
      // regular
      for (var i = 0; i < app.initMethods.length; i++) {
        app.callback(app.initMethods[i]);
      }
      // post
      for (i = 0; i < app.postInitMethods.length; i++) {
        app.callback(app.postInitMethods[i]);
      }
    });
  };

  app.log = function(message) {
    if (app.debug) console.log(message);
  };

})(window.app = {});
